#main-header {
  display: grid;
  grid-template-columns: 95px 1fr;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

#auth-link {
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  padding-left: 5px;
  font-size: 0.9em;
  align-self: center;
  cursor: pointer;
}

#auth-link:hover {
  text-decoration: underline;
}

#auth-logo {
  text-align: left;
}

#auth-text {
  text-align: center;
}

#loading-animation {
  display: grid;
  align-items: center;
}