.ReactModalPortal {
  position: relative;
  z-index: 100;
}

.modal-event {
  margin: 30px 0;
}

.modal-event:first-child {
  margin-top: 0;
}

.modal-event:last-child {
  margin-bottom: 0;
}

.modal-edit-link {
  display: grid;
  grid-template-columns: 1fr 16px;
  justify-content: space-between;
  grid-column-gap: 20px;
  text-decoration: none;
  color: #000;
}

.modal-event-date {
  margin-top: 5px;
  font-size: 0.75em;
  color: #444;
}
