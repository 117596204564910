.form-group {
  margin: 7px 0;
}

.form label,
.form input,
.form button,
.form textarea {
  font-size: 14px;
}

.form input,
.form button,
.form textarea {
  box-sizing: border-box;
  padding: 7px;
  margin: 5px;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.form input[type=text],
.form textarea {
  width: calc(100% - 10px);
}

.form textarea {
  resize: none;
}

input[type=date],
input[type=time] {
  position: relative;
  bottom: 1px;
  font-size: 1em;
}

.form-buttons {
  text-align: right;
}

.form button {
  background-color: #ffffff;
}

.form button:active {
  background-color: #f5f5f5;
}

.form .btn-warning {
  background-color: #ff5868;
  color: #fff;
}

.form .btn-warning:active {
  background-color: #ed5160;
}