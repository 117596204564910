.event {
  box-sizing: border-box;
  margin-right: 1px;
  padding: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #1ca868;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
}

.event:active {
  background-color: #18925a;
}