#calendar-grid {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: repeat(12, minmax(50px, 1fr));
}

.month {
  display: grid;
  grid-template-columns: 1.2fr repeat(31, minmax(38px, 1fr));
  justify-items: stretch;
  border-bottom: 1px solid #cccccc;
}

.month:first-child {
  border-top: 1px solid #cccccc;
}

.month-header {
  justify-self: center;
  align-self: center;
}